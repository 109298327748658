<template>
  <Menubar :model="_navItems" class="min-h-[8vh]">
    <template v-slot:start v-if="HasToggleableMenu">
      <button ref="hamburgerMenuRef" aria-label="Menu" class="p-1 mr-5 -ml-1 text-gray-500 rounded-md md:block"
        @click="toggleSidebar">
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
          <path clip-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            fill-rule="evenodd"></path>
        </svg>
      </button>
    </template>
    <template #end>
      <div class="sm:flex sm:items-center sm:ml-2">
        <div class="relative" v-if="user">
          <Button as="a" label="Aller sur l'ancien site" :href="oldURL" target="_blank" rel="noopener" class="mr-8" />
          <button v-if="user?.icon" type="button"
            class="flex text-sm rounded-full border-2 border-transparent transition focus:border-gray-300 focus:outline-none"
            @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
            <Avatar class="mr-2" icon="pi pi-user" shape="circle" size="xlarge" />
          </button>
          <span v-else class="inline-flex rounded-md">
            <button type="button"
              class="inline-flex items-center py-2 px-3 text-sm font-medium leading-4 text-gray-500 rounded-md border transition dark:text-gray-300 focus:outline-none hover:light:text-gray-700"
              @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
              {{ user?.name }}

              <svg class="ml-2 -mr-0.5 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor">
                <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </button>
          </span>
          <Menu id="overlay_menu" ref="menu" :model="profileItems" :popup="true">
          </Menu>
        </div>
        <div class="relative" v-else>
          <NuxtLink to="/login">
            <Button type="button" icon="pi pi-sign-in" label="Se connecter" :link="true" />
          </NuxtLink>
        </div>
      </div>
    </template>
  </Menubar>
</template>

<script setup lang="ts">
import { useSidebarStore } from "~/stores/sidebar";
import type { MenuItem } from "primevue/menuitem";

const { toggleSidebar } = useSidebarStore();
const hamburgerMenuRef = ref(null);
const authService = useAuthService();
const user = useSanctumUser()?.value;
const config = useRuntimeConfig();
const tenantName = ref(useTenant());
const oldURL = computed(() => {
  const serverOldHost = new URL(config.public.serverOldHost);
  let oldUrl = "";
  if (serverOldHost.protocol) {
    oldUrl += serverOldHost.protocol;
    oldUrl += "//";
  } else {
    oldUrl += "https://";
  }
  oldUrl += tenantName.value + ".";
  oldUrl += serverOldHost.host;
  return oldUrl;
});
defineExpose({
  hamburgerMenuRef,
});
const props = defineProps({
  navItems: {
    type: Array<MenuItem>,
    required: false,
  },
  HasToggleableMenu: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const menu = ref();
const profileItems = ref<MenuItem[]>([]);
const _navItems = ref<MenuItem[]>(props.navItems || []);

onMounted(() => {
  if (user) {
    profileItems.value.push({
      label: "Mon compte",
      items: [
        /*{
            label: 'Profil',

          },
            {
              label: 'Paramètres du compte',

            },*/ {
          label: "Se déconnecter",
          command: async () => {
            await authService.logout();
            await authService.resetAuthCookie();
            await authService.resetConnectedUserData();
            await navigateTo("/login");
          },
        },
      ],
    });
  }
});

const toggle = (event) => {
  menu.value.toggle(event);
};
</script>
