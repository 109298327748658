
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_931Tlc45EVOKMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/companies/[id].vue?macro=true";
import { default as indexZtYFjnIW8rMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/companies/index.vue?macro=true";
import { default as indexlIRcZ9oB1CMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/index.vue?macro=true";
import { default as indexHaJtIR4TujMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/invoices/index.vue?macro=true";
import { default as noactive_45invoicesHClteHhutZMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/invoices/noactive-invoices.vue?macro=true";
import { default as _91id_93CHK7gdDlu8Meta } from "/home/ploi/front.dadoun.co/pages/[tenant]/invoices/show/[id].vue?macro=true";
import { default as _91id_93kH73i0Q9xXMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/markets/[id].vue?macro=true";
import { default as indexaLVyRMKim5Meta } from "/home/ploi/front.dadoun.co/pages/[tenant]/markets/index.vue?macro=true";
import { default as _91id_93ys4AMRmqEJMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/merchants/[id].vue?macro=true";
import { default as indexZUtGh8IQ7KMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/merchants/index.vue?macro=true";
import { default as indexEvB2Scxy6hMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/placers/index.vue?macro=true";
import { default as indexRVmBJAK9yIMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/renewals/index.vue?macro=true";
import { default as indexeWVw2ekpyOMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/settings/index.vue?macro=true";
import { default as securityJzm2dnKzVrMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/settings/security.vue?macro=true";
import { default as create_45editwQJ7U8yLKdMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/create-edit.vue?macro=true";
import { default as index7P9ZXf8fIFMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/index.vue?macro=true";
import { default as _91id_936w0yxb0wSvMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/[id].vue?macro=true";
import { default as createl1TvfgfI7lMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/create.vue?macro=true";
import { default as indexp9uADxhzSnMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/index.vue?macro=true";
import { default as showBMFaUmEBSSMeta } from "/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/show.vue?macro=true";
import { default as forget_45passwordXuvTez41uVMeta } from "/home/ploi/front.dadoun.co/pages/forget-password.vue?macro=true";
import { default as indexTJDgGZB2fdMeta } from "/home/ploi/front.dadoun.co/pages/index.vue?macro=true";
import { default as loginQLVAOfIwoQMeta } from "/home/ploi/front.dadoun.co/pages/login.vue?macro=true";
import { default as _91email_93pIJByehOxBMeta } from "/home/ploi/front.dadoun.co/pages/reset-password/[token]/[email].vue?macro=true";
import { default as indexGDISC045JNMeta } from "/home/ploi/front.dadoun.co/pages/reset-password/[token]/index.vue?macro=true";
import { default as create_45edityHQriPQoOjMeta } from "~/pages/[tenant]/subscriptions/create-edit.vue?macro=true";
export default [
  {
    name: "tenant-companies-id",
    path: "/:tenant()/companies/:id()",
    meta: { ...(_91id_931Tlc45EVOKMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/companies/[id].vue")
  },
  {
    name: "tenant-companies",
    path: "/:tenant()/companies",
    meta: { ...(indexZtYFjnIW8rMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/companies/index.vue")
  },
  {
    name: "tenant",
    path: "/:tenant()",
    meta: { ...(indexlIRcZ9oB1CMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/index.vue")
  },
  {
    name: "tenant-invoices",
    path: "/:tenant()/invoices",
    meta: { ...(indexHaJtIR4TujMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/invoices/index.vue")
  },
  {
    name: "tenant-invoices-noactive-invoices",
    path: "/:tenant()/invoices/noactive-invoices",
    meta: { ...(noactive_45invoicesHClteHhutZMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/invoices/noactive-invoices.vue")
  },
  {
    name: "tenant-invoices-show-id",
    path: "/:tenant()/invoices/show/:id()",
    meta: { ...(_91id_93CHK7gdDlu8Meta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/invoices/show/[id].vue")
  },
  {
    name: "tenant-markets-id",
    path: "/:tenant()/markets/:id()",
    meta: { ...(_91id_93kH73i0Q9xXMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/markets/[id].vue")
  },
  {
    name: "tenant-markets",
    path: "/:tenant()/markets",
    meta: { ...(indexaLVyRMKim5Meta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/markets/index.vue")
  },
  {
    name: "tenant-merchants-id",
    path: "/:tenant()/merchants/:id()",
    meta: { ...(_91id_93ys4AMRmqEJMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/merchants/[id].vue")
  },
  {
    name: "tenant-merchants",
    path: "/:tenant()/merchants",
    meta: { ...(indexZUtGh8IQ7KMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/merchants/index.vue")
  },
  {
    name: "tenant-placers",
    path: "/:tenant()/placers",
    meta: { ...(indexEvB2Scxy6hMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/placers/index.vue")
  },
  {
    name: "tenant-renewals",
    path: "/:tenant()/renewals",
    meta: { ...(indexRVmBJAK9yIMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/renewals/index.vue")
  },
  {
    name: "tenant-settings",
    path: "/:tenant()/settings",
    meta: { ...(indexeWVw2ekpyOMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/settings/index.vue")
  },
  {
    name: "tenant-settings-security",
    path: "/:tenant()/settings/security",
    meta: {"middleware":["nuxt-permissions","permissions","sanctum:auth"]},
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/settings/security.vue")
  },
  {
    name: "tenant-subscriptions-create-edit",
    path: "/:tenant()/subscriptions/create-edit",
    meta: { ...(create_45editwQJ7U8yLKdMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/create-edit.vue")
  },
  {
    name: "tenant-subscriptions",
    path: "/:tenant()/subscriptions",
    meta: { ...(index7P9ZXf8fIFMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/index.vue")
  },
  {
    name: "tenant-subscriptions-renewal_exceptions-id",
    path: "/:tenant()/subscriptions/renewal_exceptions/:id()",
    meta: { ...(_91id_936w0yxb0wSvMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/[id].vue")
  },
  {
    name: "tenant-subscriptions-renewal_exceptions-create",
    path: "/:tenant()/subscriptions/renewal_exceptions/create",
    meta: { ...(createl1TvfgfI7lMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/create.vue")
  },
  {
    name: "tenant-subscriptions-renewal_exceptions",
    path: "/:tenant()/subscriptions/renewal_exceptions",
    meta: { ...(indexp9uADxhzSnMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/index.vue")
  },
  {
    name: "tenant-subscriptions-show",
    path: "/:tenant()/subscriptions/show",
    meta: {"middleware":["nuxt-permissions","permissions","sanctum:auth"]},
    component: () => import("/home/ploi/front.dadoun.co/pages/[tenant]/subscriptions/show.vue")
  },
  {
    name: "forget-password",
    path: "/forget-password",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.dadoun.co/pages/forget-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: { ...(indexTJDgGZB2fdMeta || {}), ...{"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]} },
    component: () => import("/home/ploi/front.dadoun.co/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.dadoun.co/pages/login.vue")
  },
  {
    name: "reset-password-token-email",
    path: "/reset-password/:token()/:email()",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.dadoun.co/pages/reset-password/[token]/[email].vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.dadoun.co/pages/reset-password/[token]/index.vue")
  },
  {
    name: "invoiceEdit",
    path: "/invoices/edit",
    meta: { ...(create_45edityHQriPQoOjMeta || {}), ...{"middleware":["sanctum:auth","nuxt-permissions"]} },
    component: () => import("~/pages/[tenant]/subscriptions/create-edit.vue")
  },
  {
    name: "invoiceCreate",
    path: "/invoices/create",
    meta: {"middleware":["sanctum:auth","nuxt-permissions"]},
    component: () => import("~/pages/[tenant]/subscriptions/create-edit.vue")
  }
]