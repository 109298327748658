<template>
  <div class="grid bg-gray-50 dark:bg-gray-900 grid-rows-[auto_1fr_auto] h-[100vh]">
    <main class="overflow-y-auto flex-col h-screen bg-white dark:bg-gray-700">
      <div class="container flex max-w-full min-h-screen">
        <slot />
      </div>
    </main>
    <Footer />
  </div>
</template>
<script setup lang="ts"></script>
