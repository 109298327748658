import type { RouteLocationNormalized } from "vue-router";
import type { ModuleOptions } from "../types";
import { AccessManager } from "./accessManager";

export function handleRouteNavigation(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  accessManager: AccessManager,
  config: ModuleOptions,
): boolean | string {
  // Extract permissions and roles from route metadata
  const routePermissions = to.meta?.permissions as
    | string
    | string[]
    | undefined;
  const routeRoles = to.meta?.roles as string | string[] | undefined;

  // If no permissions or roles are required, allow access
  if (!routePermissions && !routeRoles) {
    return true;
  }

  // Use our AccessManager to check if the user has the required access
  const hasAccess = accessManager.checkAccess(routePermissions, routeRoles);

  // If the user has access, allow navigation
  if (hasAccess) {
    return true;
  }

  // Handle unauthorized access based on configuration
  if (!config.redirectIfNotAllowed) {
    // If we're already on this page, return false to prevent navigation
    if (from.fullPath === to.fullPath) {
      return false;
    }
    // Otherwise, return to the previous page
    return from.fullPath;
  }

  // Redirect to the configured path or default to home
  return config.redirectIfNotAllowed || "/";
}
