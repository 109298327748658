<template>
  <!-- Subcription header -->
  <AtomsNavLink :navLinks="[{ route: '/subscriptions', name: 'Abonnements' }]" v-if="!this.$route.query.type"
    v-can="[Permissions.ViewSubscriptions]" v-role="[Role.ADMIN, Role.SUPER_ADMIN]" />
  <SubForm :key="componentKeySub" v-model="subscription" :toValidate="toValidate" :editMode="editMode"
    @selectionMarket="selectionMarket" @createOrUpdate="createOrUpdateSubscription" @resetState="resetState"
    v-if="!this.$route.query.type" />

  <!-- Invoice header -->
  <AtomsNavLink :navLinks="[
    { route: '/invoices', name: 'Factures' },
    { route: `/invoices/show/${route.params.id}`, name: invoice.uuid ? `Facture n°${invoice.uuid}` : '' },
  ]" v-if="this.$route.query.type && this.$route.query.type == 'invoice' && invoice && invoice.id"
    v-can="[Permissions.ViewInvoices, Permissions.ViewInvoice]" v-role="[Role.ADMIN, Role.SUPER_ADMIN]" />

  <invoiceEdit :key="componentKeyInv" v-model="invoice" @createOrUpdate="createOrUpdateInvoice" :toValidate="toValidate" :editMode="editMode"
    @selectionMarket="selectionMarket" @resetState="resetState" @selectionLinkedInvoice="selectionLinkedInvoice" :type="this.$route.query.type"
    v-can="[Permissions.EditInvoice, Permissions.CreateInvoice]" v-role="[Role.ADMIN, Role.SUPER_ADMIN]"
    v-if="this.$route.query.type && editInvoice" />

  <div class="grid justify-center grid-cols-12 px-2 mt-4 mb-2">
    <div class="col-span-4 h-full rounded-md bg-white border-[0.5px] shadow-md dark:bg-surface-800 dark:border-none">
      <div class="grid w-full grid-cols-6 p-2">
        <div class="col-span-1">
          <span class="block text-sm font-medium leading-6 text-gray-900 dark:text-primary-50 ">Articles</span>
        </div>
        <div class="flex flex-wrap gap-4 col-span-3">
          <div class="flex items-center gap-2">
              <RadioButton v-model="filterProducts" inputId="valide" name="valide" value="valid" size="small" @change="filteringProducts" />
              <label for="valide" class="text-xs">Valides</label>
          </div>
          <div class="flex items-center gap-2">
              <RadioButton v-model="filterProducts" inputId="old" name="old" value="unvalid" size="small" @change="filteringProducts" />
              <label for="old" class="text-xs">Invalides</label>
          </div>
          <div class="flex items-center gap-2">
              <RadioButton v-model="filterProducts" inputId="future" name="future" value="future" size="small"  @change="filteringProducts" />
              <label for="future" class="text-xs">Futurs</label>
          </div>
        </div>
        <div class="flex justify-end col-span-2">
          <!-- <label for="searchProduct" class="flex text-xs align-middle">Rechercher</label> -->
          <InputText id="searchProduct" v-model="search"
            class="w-40 h-8 p-0.5 ml-2 dark:!bg-surface-800 dark:!text-primary-50 " @change="searching" />
        </div>
      </div>
      <div class="p-1 card1 w-full h-[42rem] flex justify-center">
        <draggable :key="componentProducts"
          class="dragArea list-group w-full overflow-y-auto rounded-md bg-white border-[0.5px] shadow-md dark:bg-surface-800 dark:border-none"
          :list="filteredProducts" :group="{ name: 'people', pull: 'clone', put: false }" :sort="true" @change="log"
          :move="checkMove" :scroll-sensitivity="200" :force-fallback="true">
          <div
            class="list-group-item bg-white shadow-lg m-1 p-1 border-[0.5px] rounded-md text-left text-xs dark:bg-primary-700 dark:text-primary-50 dark:border-none cursor-move"
            :class="{ '!bg-blue-400 !text-white': (element?.sliced_price && element?.sliced_price == 1),
             '!bg-red-400 !text-white': (element?.end_date && new Date(element?.end_date) < new Date()),
             '!bg-violet-400 !text-white': (element?.start_date && new Date(element?.start_date) > new Date()) }"
            v-for="element in filteredProducts" :key="element?.id">
            <div class="flex justify-between">
              <div>
                <span 
                v-tooltip="'ID: '+element?.id+'\nPU: '+(element?.pu_ht ? element?.pu_ht : ' ND ' )+'€ \n PU ABO: '+(element?.pu_ht_abo ? element?.pu_ht_abo : ' ND ' )+'€ \n Début: '+format3(element?.start_date)+'\n Fin: '+format3(element?.end_date)
                ">
                {{ element?.code }}</span> - {{ element?.name }} 
              </div>
              <div>
                <i class="pi pi-chart-line mr-4" v-tooltip="'Tarif progressif / dégressif'"
                  style="font-size: 0.80rem;color: white;"
                  v-if="(element?.sliced_price && element?.sliced_price == 1)"></i>
                <i class="pi pi-exclamation-circle mr-4" v-tooltip="'Ce produit n\'est plus valide'"
                  style="font-size: 0.80rem;color: red;"
                  v-if="(element?.end_date && new Date(element?.end_date) < new Date())"></i>
                <i class="pi pi-exclamation-circle mr-4" v-tooltip="'Les tarifs progressifs ne sont pas disponibles'"
                  style="font-size: 0.80rem;color: red;"
                  v-if="(element?.sliced_price && element?.sliced_price == 1 && (!element?.products_range_prices || element?.products_range_prices.length == 0))"></i>
                <i class="pi pi-hourglass mr-4" v-tooltip="'Ce produit n\'est pas encore actif'"
                  style="font-size: 0.80rem;color: white;"
                  v-if="(element?.start_date && new Date(element?.start_date) > new Date())"></i>
                <i class="pi pi-plus" style="font-size: 0.75rem" @click="addProduct(element)"></i>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <div class="col-span-8 h-full">
      <div class="px-2 h-full rounded-md bg-white border-[0.5px] shadow-md ml-2">
        <div class=" p-2 flex w-full justify-between h-10">
          <div>
            <span class="block text-sm font-medium leading-6 text-gray-900 dark:text-primary-50 ml-2"
              v-if="!this.$route.query.type">
              Détail de l'offre
            </span>
            <span class="block text-sm font-medium leading-6 text-gray-900 dark:text-primary-50 ml-2"
              v-if="this.$route.query.type">
              Détail de la facture
            </span>
          </div>
          <div class="flex w-52 justify-between text-xs !font-semibold"
          :class="{'!w-64': this.$route.query.type && invoice.id != null && invoice.status == 15 }" v-if="invoice.status == 15">
            <groupForm @newgroup="doNewGroup" v-if="invoice.status == 15"/>
            <div>
              <Button label="Enregistrer" size="small" severity="info" @click="validating()" 
              class="rounded !px-1 !py-1 text-sm font-semibold text-white shadow-sm" v-if="invoice.status == 15" />
              <Button label="Emettre" size="small" severity="success" @click="emitting()" 
                class=" ml-2 rounded !px-1 !py-1 text-sm font-semibold text-white shadow-sm" v-if="this.$route.query.type && invoice.id != null && invoice.status == 15 && invoice.invoice_type_id != 3"/>
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="loading-overlay dark:bg-surface-800 dark:text-primary-50">
          <div class="spinner"></div>
        </div>
        <div class="p-1 mx-2 min-h-80 align-top top-0">
          <InvoiceDetailHeader />
          <div id="-2" class="mt-1 w-full h-full flex justify-center ">
            <dragdrop :main="true" :id="-1" :name="-1" :key="componentKey" class="dragArea list-group w-full p-1  "
              :list="location" group="people" @change="log" :move="checkMove" :sort="true" v-if="show"
              @deletegroupDD="doDeleteGroup" @pushlineDD="newline" @updatingLineDD="refresh" @deleteline="doDelete"
              @updatingLine="refresh" @updatelist="refresh">
            </dragdrop>
          </div>
        </div>
        <div>
          <resume :market="selectedMarket" :list="location" :key="componentKey" />
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Dragdrop from '~/components/subscriptions/dragdrop'
import Resume from '~/components/subscriptions/resume'
import GroupForm from '~/components/subscriptions/groupForm'
import SubForm from '~/components/subscriptions/form'
import ModelSearch from '~/components/atoms/model/search'
import InputText from 'primevue/inputtext';
import LineProduct from '~/components/products/line.vue'
import InvoiceEdit from "~/components/invoices/edit.vue";
import InvoiceDetailHeader from "~/components/invoices/invoiceDetailHeader.vue";
import type { Invoice } from "~/types/Invoice"
import type { InvoiceDetail } from "~/types/Invoice"
import type { Subscription } from "~/types/Subscription"
import type { Market } from "~/types/Market"
import type { Product } from "~/types/Product"
import type { RenewalPeriod } from "~/types/Renewal"
import Role from "~/types/Role";
import Permissions from "~/types/Permissions"
import moment from 'moment'

definePageMeta({
  roles: [Role.ADMIN, Role.SUPER_ADMIN, Role.MANAGER],
  permissions: [Permissions.EditInvoice, Permissions.CreateInvoice]
});
export default defineComponent({
  name: 'Subscriptions-Invoices-create-edit',
  components: {
    draggable: VueDraggableNext,
    dragdrop: Dragdrop,
    invoiceEdit: InvoiceEdit,
    resume: Resume,
    groupForm: GroupForm,
    modelSearch: ModelSearch,
    LineProduct: LineProduct,
    InvoiceDetailHeader: InvoiceDetailHeader,
    SubForm: SubForm,
  }, async setup() {
    const apiFetch = useSanctumClient();
    const { $eventBus } = useNuxtApp();
    const { data: taxes } = await useAsyncData("taxes", () => apiFetch("/api/tax"))
    return { apiFetch, $eventBus, Permissions, Role };
  },
  data() {
    return {
      count: 0,
      componentKey: 0,
      componentKeySub: 0,
      componentKeyInv: 100,
      componentSearch: 0,
      componentProducts: 0,
      show: true,
      enabled: true,
      search: "",
      products: [],
      location: [],
      dragging: false,
      notValid: false,
      isVariant: false,
      route: useRoute(),
      subscription: <Subscription>{
        id: null,
        market_id: null,
        merchant_id: null,
        billing_period_id: null,
        nb_days: 1,
        start_date: null,
        end_date: null,
        status: 11,
        created_at: null,
        updated_at: null,
        activity_id: null,
        ml: null,
        is_producer: 0,
        offer_invoice: [],
        last_invoice: [],
        market: null,
        comment: null
      },
      invoice: <Invoice>{
        id: null,
        uuid: null,
        object: null,
        comment: null,
        comment_visible: null,
        total_excl_tax: 0,
        total_incl_tax: 0,
        total_vat: 0,
        due: 0,
        invoice_type_id: (this.$route.query.type == 'invoice') ? 2 : ((this.$route.query.type == 'credit') ? 4 : ((this.$route.query.type == 'ticket') ? 1 : 5)),
        company_id: null,
        merchant_id: null,
        subscription_id: null,
        placer_id: null,
        market: <Market>{
          accountancy_analytics: null,
          accountancy_code: null,
          active_subscriptions_count: 0,
          banner_img: null,
          billing_day_of_month: null,
          billing_period_id: null,
          city_id: null,
          city: {
            id: null,
            name: null,
          },
          color: null,
          contacts: [],
          contacts_count: 0,
          description: null,
          enable: null,
          global_id: null,
          id: null,
          invoicing_market_counter: null,
          invoicing_market_key: null,
          market_days: [],
          ml: null,
          ml_covered: null,
          name: null,
          nb_corners: null,
          slug: null,
          ticket_footer: null,
          ticket_header: null,
        },
        market_making_id: null,
        status: 15,
        created_at: null,
        updated_at: null,
        linked_invoice_id: null,
        linked_invoice_uuid: null,
        market_id: null,
        merchant_name: null,
        merchant_addresse: null,
        merchant_comp_addresse: null,
        merchant_zipcode: null,
        merchant_city: null,
        legal_notice_col1: null,
        legal_notice_col2: null,
        market_date: null,
        invoice_details: [],
        last_renewal_period: <RenewalPeriod>{
          id: null,
          period_start: null, 
          period_end: null, 
          subscription_id: null,
          invoice_id: null,
          active_offer: null,
        },
      },
      selectedMarket: <Market>{},
      user: useSanctumUser()?.value,
      toValidate: false,
      editMode: true,
      isLoading: false,
      editInvoice: false,
      filterProducts: "valid",
      filteredProducts: [],
      validProducts: [],
      unvalidProducts: [],
      futurProducts: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    add() {
      //console.log('add')
    },
    replace() {
      //console.log('replace')
    },
    checkMove(event) {
      if (event.draggedContext.element && 'invoiceDetailGroup' in event.draggedContext.element) {
        return false
      }
    },
    log(event) {
      //console.log("main log", event)
      const { moved, added, removed } = event
      if (moved) {
        //console.log('moved', moved)
        this.componentKey += 1
      }
      if (added) {
        //console.log('added', added, added.element)
        this.componentKey += 1
      }
      if (removed) {
        //console.log('removed', removed, removed.element)
        this.componentKey += 1
      }
    },

    format(date: Date) {
      return date ? date.toLocaleString().split(' ')[0] : null;
    },

    format2(date: String) {
        return moment(moment.parseZone(date), "YYYY-DD-MM HH:mm:ss").toDate();
    },

    format3(date: Date) {
        return moment(moment.parseZone(date), "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    },

    async searching() {
      if (this.subscription.market_id || this.invoice.market_id) {
        let id = this.subscription.market_id ? this.subscription.market_id : this.invoice.market_id
        this.products = await useAsyncData(() => this.apiFetch(`/api/markets/${id}/products/search`, {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: {
            search: { value: this.search },
            filters: [{ "field": "activated", "operator": "=", "value": "1" }],
            includes: [{ "relation": "productsRangePrices" }, { "relation": "tax" }]
          },
        }));
        this.products = this.products.data;
        this.filtrerProducts(this.products.data);
      } else {
        this.products = [];
      }
    },

    selectionMarket(marketId: number, market: Market) {
      if (!marketId && this.selectedMarket.id != marketId)
        this.location = [];
      this.selectedMarket = market;

      this.searching();
    },

    selectionLinkedInvoice(invoice: Invoice) {
      if(invoice) {
        this.location = [];
        invoice.id=null;
        invoice.uuid=null;
        this.invoice = invoice;

        if (this.invoice && this.invoice.market) {
          this.editInvoice = true;
          this.selectedMarket = this.invoice.market;
          if(this.invoice.market_date) {
            this.invoice.market_date = this.format3(this.invoice.market_date);
          }
          let groupLists = Object.groupBy(this.invoice.invoice_details, ({ group_id }) => group_id);
          let groups = [];
          this.invoice.invoice_details.forEach((detail: InvoiceDetail, index: number) => {
            if (detail.groups) {
              if (!groups.find((element) => element === detail.group_id)) {
                this.doNewGroup(detail.groups.name, groupLists[detail.group_id]);
                groups.push(detail.group_id);
              }
            } else {
              this.location.push(detail);
            }
          });
          this.searching();
          this.componentKey += 1;
        }
      }
    },

    async load() {
      //this.isLoading = true;
      if (this.$route.query.id) {
        let id = this.$route.query.id
        const resp = await this.apiFetch(`/api/subscriptions/${id}?include=market,offerInvoice,offerInvoice.invoiceDetails,offerInvoice.invoiceDetails.product,offerInvoice.invoiceDetails.product.productsRangePrices,offerInvoice.invoiceDetails.tax,offerInvoice.invoiceDetails.groups`, {
          method: 'GET',
          headers: { "Content-Type": "application/json" },
        });
        this.subscription = resp?.data;

        if (this.subscription) {
          if (this.subscription.market) {
            this.selectedMarket = this.subscription.market;
          }

          if (this.subscription.offer_invoice[0]) {
            let groupLists = Object.groupBy(this.subscription.offer_invoice[0].invoice_details, ({ group_id }) => group_id);
            let groups = [];
            this.subscription.offer_invoice[0].invoice_details.forEach((detail: InvoiceDetail, index: number) => {
              if (detail.groups) {
                if (!groups.find((element) => element === detail.group_id)) {
                  this.doNewGroup(detail.groups.name, groupLists[detail.group_id]);
                  groups.push(detail.group_id);
                }
              } else {
                this.location.push(detail);
              }
            });
          }
          this.searching();
          this.editMode = false;
          this.componentKeySub += 1;
          this.componentSearch += 1;
          this.componentKey += 1;
        }
      } else if (this.$route.query.invoiceId) {
        let invoiceId = this.$route.query.invoiceId
        const resp = await this.apiFetch(`/api/invoices/${invoiceId}?include=payments,merchant,company,market,subscription,statut,invoicePayments,invoiceDetails,placer,invoiceType,renewalPeriods,last_renewal_period,invoiceDetails.tax,invoiceDetails.product,invoiceDetails.product.productsRangePrices,market.city,payments.paymentMethod,payments.deposit,invoiceDetails.groups`, {
          method: 'GET',
          headers: { "Content-Type": "application/json" },
        });
        this.invoice = resp?.data;
        if (this.invoice && this.invoice.market) {
          this.editInvoice = true;
          this.selectedMarket = this.invoice.market;
          if(this.invoice.market_date) {
            this.invoice.market_date = this.format3(this.invoice.market_date);
          }
          if(this.invoice.last_renewal_period?.period_start) {
            this.invoice.last_renewal_period.period_start = this.format3(this.invoice.last_renewal_period?.period_start);
          } else if (this.invoice.market_date){
            this.invoice.last_renewal_period.period_start = this.format3(this.invoice.market_date);  
          }
          if(this.invoice.last_renewal_period?.period_end) {
            this.invoice.last_renewal_period.period_end = this.format3(this.invoice.last_renewal_period?.period_end);    
          } else if (this.invoice.market_date){
            this.invoice.last_renewal_period.period_end = this.format3(this.invoice.market_date);  
          }
          let groupLists = Object.groupBy(this.invoice.invoice_details, ({ group_id }) => group_id);
          let groups = [];
          this.invoice.invoice_details.forEach((detail: InvoiceDetail, index: number) => {
            if (detail.groups) {
              if (!groups.find((element) => element === detail.group_id)) {
                this.doNewGroup(detail.groups.name, groupLists[detail.group_id]);
                groups.push(detail.group_id);
              }
            } else {
              this.location.push(detail);
            }
          });
          this.searching();
          this.componentKey += 1;
          if (this.invoice.status == 15) {
            this.editMode = true;
          } else {
            this.editMode = false;
          }
        }
      } else if(this.$route.query.type) {
        this.editInvoice = true;
      }


      //this.isLoading = false;
    },

    validating() {
      this.toValidate = true;
    },

    resetState() {
      this.toValidate = false;
    },

    async createOrUpdateSubscription() {
      this.isLoading = true;
      if (this.subscription.market_id && this.subscription.merchant_id && this.subscription.start_date) {
        this.subscription.start_date = this.format(this.subscription.start_date.toString());
        if (this.subscription.end_date) {
          this.subscription.end_date = this.format(this.subscription.end_date.toString());
        }
        let resp = await this.apiFetch(`/api/subscription/upsert`, {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: {
            subscription: this.subscription,
            offer: this.location,
          },
        }).catch((error) => {
          const formattedError = useApiError(error); //return{isValidationError,code,bag,message}
          console.log(formattedError)
          this.$eventBus.emit("toast", {
            severity: "error",
            summary: "Une erreur s'est produite",
            group: "subscription-status",
            unique: true,
            life: 2000,
          });
        });
        this.subscription = resp;
        if (this.subscription) {
          this.editMode = false;
          this.$router.replace({
            ...this.$router.currentRoute,
            query: { id: this.subscription.id }
          });
        }
      }
      this.searching();
      this.resetState();
      this.isLoading = false;
      this.$eventBus.emit("toast", {
        severity: "success",
        summary: "L'abonnement a bien été mis à jour.",
        group: "subscription-status",
        unique: true,
        life: 2000,
      });
    },

    async createOrUpdateInvoice() {
      this.isLoading = true;
      if (this.invoice.market_id && this.invoice.merchant_id) {
        if(this.invoice.market_date)
          this.invoice.market_date = this.format(this.invoice.market_date);
        try {
          let resp = await this.apiFetch(`/api/invoice/upsert`, {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: {
            invoice: this.invoice,
            details: this.location,
          },
          });
          this.invoice = resp;
          if (this.invoice) {
            if(this.invoice.last_renewal_period?.period_start) {
              this.invoice.last_renewal_period.period_start = this.format3(this.invoice.last_renewal_period.period_start);
            } else if (this.invoice.market_date) {
              this.invoice.last_renewal_period.period_start = this.format3(this.invoice.market_date);  
            }
            if(this.invoice.last_renewal_period?.period_end) {
              this.invoice.last_renewal_period.period_end = this.format3(this.invoice.last_renewal_period.period_end);    
            } else if (this.invoice.market_date) {
              this.invoice.last_renewal_period.period_end = this.format3(this.invoice.market_date);  
            }
            this.$router.replace({
              ...this.$router.currentRoute,
              query: { invoiceId: this.invoice.id, type: this.$route.query.type }
            });
            if (this.invoice.status == 15) {
              this.editMode = true;
            } else {
              this.editMode = false;
            }
            if(this.invoice.market_date) {
              this.invoice.market_date = this.format3(this.invoice.market_date);
            }
            this.componentKeyInv += 1;
          }
          this.searching();
          this.resetState();
          this.isLoading = false;
          this.$eventBus.emit("toast", {
            severity: "success",
            summary: "La facture a bien été mise à jour.",
            group: "invoice-status",
            life: 2000,
          });
        } catch(error) {
          const formattedError = useApiError(error); //return{isValidationError,code,bag,message}
          this.$eventBus.emit("toast", {
            severity: "error",
            summary: "Une erreur s'est produite",
            group: "invoice-status",
            life: 2000,
          });
        }
      }
      this.isLoading = false;
    },

    async emitting() {
      if(this.invoice.id) {
        this.isLoading = true;  
        try{
          const resp = await this.apiFetch(`/api/invoice/emit`, {
              method: 'POST',
              headers: { "Content-Type": "application/json" },
              body: {
                invoiceId: this.invoice.id,
              },
          });
          this.invoice = resp;
          this.componentKeyInv += 1;
        } catch(error) {
          const formattedError = useApiError(error);
          this.$eventBus.emit("toast", {
            severity: "error",
            summary: "Une erreur s'est produite",
            group: "invoice-status",
            life: 2000,
          });
        }
        this.isLoading = false;
      }

    },
    refresh() {
      //console.log("refresh")
      this.componentKey += 1
    },
    doDelete(index) {
      //console.log("delete line", index);
      this.location.splice(index, 1);
      this.componentKey += 1
    },
    doDeleteGroup(index) {
      //console.log("delete group", index);
      this.location.splice(index, 1)
      this.componentKey += 1
    },
    doNewGroup(groupName, list = []) {
      let invoiceDetailGroup = {
        id: null,
        name: groupName,
        location_id: null,
        order: this.location.length
      };

      let invoiceDetailGroupLine = {
        invoiceDetailGroup: invoiceDetailGroup,
        list: list,
      }

      this.location.push(invoiceDetailGroupLine);
    },

    newline(index: Number | undefined, line: InvoiceDetail) {
      this.location[index] = line;
      this.componentKey += 1
    },

    addProduct(product: Product) {
      this.location.push(product);
    },

    filteringProducts() {
      console.log(this.filterProducts)
      switch (this.filterProducts) {
        case "valid":
          this.filteredProducts = this.validProducts;
          break;
        case "unvalid":
          this.filteredProducts = this.unvalidProducts;
          break;
        case "future":
          this.filteredProducts = this.futurProducts;
          break;
        default:
          this.filteredProducts = this.products.data;
          break;
      }
      this.componentProducts++;
    },

    filtrerProducts(products: Product[]) {
      const today = new Date();  // Date du jour

      this.validProducts = [];
      this.unvalidProducts = [];
      this.futurProducts = [];
      products.forEach(product => {
          const startDate = new Date(product.start_date);
          const endDate = new Date(product.end_date);
          if (today >= startDate && today <= endDate) {
              this.validProducts.push(product);  // Produit valide
          } else if (today > endDate) {
              this.unvalidProducts.push(product);  // Produit invalide
          } else if (today < startDate) {
              this.futurProducts.push(product);  // Produit futur
          }
      });
      this.filteringProducts();
    },
  },


});
</script>

<style scoped>
.item-attribute {
  padding: 10px;
  border: 1px solid black;
}

.clone-grid {
  display: flex;
}

.tooltipProduct {
  font-size:small;
  width: 90px;
}

/* :deep(input,
[type="text"]) {
  width: 100%;
  appearance: none;
  background-color: #fff;
  border-width: 0px;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-ring-inset: inset;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
} */

:deep(.p-inputnumber-input) {
    width: 100%;
    padding-top: 0rem;;
    padding-right: 0.5rem;
    padding-bottom: 0rem;
    padding-left: 0.5rem;
    font-size: 0.75rem;
}

/*
:deep(.p-inputnumber-button-group) {
  position: relative;
  width: 0.75rem;
  padding-top: 0rem;;
  padding-right: 1rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
  justify-content: center;
}

:deep(.p-inputnumber-button) {
  width: 0.60rem;
  font-weight: 200;
}  */ 





.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
