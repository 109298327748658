import { defineNuxtPlugin, addRouteMiddleware, useRuntimeConfig } from "#app";
import { AccessManager } from "../services/accessManager";
import { handleRouteNavigation } from "../services/middleware";
import type { ModuleOptions } from "../types";
import { useRoles, usePermissions } from "./composables";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public.nuxtPermissions as ModuleOptions;
  const userRoles = useRoles();
  const userPermissions = usePermissions();
  const accessManager = new AccessManager(userRoles, userPermissions, config);

  addRouteMiddleware("nuxt-permissions", (to, from) => {
    return handleRouteNavigation(to, from, accessManager, config);
  });
  createCombinedDirective(nuxtApp,accessManager);

  nuxtApp.vueApp.directive("access", {
    mounted(el, binding) {
      const { permissions, roles } = binding.value;

      if (binding.arg === "not") {
        if (accessManager.checkAccess(permissions, roles)) {
          el.remove();
        }
        return;
      }

      if (!accessManager.checkAccess(permissions, roles)) {
        el.remove();
      }
    },
  });

  return {
    provide: {
      hasRole: (roles: string | string[]) =>
        accessManager.checkAccess(undefined, roles),
      hasPermission: (permissions: string | string[]) =>
        accessManager.checkAccess(permissions, undefined),
    },
  };
});

function createCombinedDirective(nuxtApp, accessManager) {
  const permissionsMap = new WeakMap();
  const rolesMap = new WeakMap();

  function updateElement(el) {
    const permissions = permissionsMap.get(el);
    const roles = rolesMap.get(el);

    const hasAccess = accessManager.checkAccess(permissions, roles);

    if (!hasAccess) {
      el.remove();
    }
  }

  // Register both directives
  nuxtApp.vueApp.directive("can", {
    mounted(el, binding) {
      if (binding.arg === "not") {
        // Handle "not" case separately
        if (accessManager.checkAccess(binding.value, undefined)) {
          el.remove();
        }
        return;
      }

      // Store the permissions
      permissionsMap.set(el, binding.value);
      // Check combined access
      updateElement(el);
    },
    updated(el, binding) {
      if (binding.arg === "not") {
        if (accessManager.checkAccess(binding.value, undefined)) {
          el.remove();
        }
        return;
      }

      permissionsMap.set(el, binding.value);
      updateElement(el);
    },
    unmounted(el) {
      permissionsMap.delete(el);
    },
  });

  nuxtApp.vueApp.directive("role", {
    mounted(el, binding) {
      if (binding.arg === "not") {
        if (accessManager.checkAccess(undefined, binding.value)) {
          el.remove();
        }
        return;
      }

      // Store the roles
      rolesMap.set(el, binding.value);
      // Check combined access
      updateElement(el);
    },
    updated(el, binding) {
      if (binding.arg === "not") {
        if (accessManager.checkAccess(undefined, binding.value)) {
          el.remove();
        }
        return;
      }

      rolesMap.set(el, binding.value);
      updateElement(el);
    },
    unmounted(el) {
      rolesMap.delete(el);
    },
  });
}
