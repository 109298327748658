import Echo from "laravel-echo";

import { WaveConnector } from "laravel-wave";
import { useCookie } from "#app";

export default defineNuxtPlugin(async () => {
  const runtimeConfig = useRuntimeConfig();
  const authConfig = runtimeConfig.public.sanctum;
  const tenant = useTenant();
  const apiFetch = useSanctumClient();
  const user = useSanctumUser()?.value;

  let token = await apiFetch(`/api/user/${user?.id}/channel/token`).catch(
    (err) => console.log(err),
  );
  const authHeaders = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    [authConfig.csrf.header]: !authConfig.token
      ? useCookie(authConfig.csrf.cookie).value
      : null,
    "X-Tenant": tenant,
  };
  const echo = new Echo({
    broadcaster: WaveConnector,
    endpoint: `${runtimeConfig.public.sseUrl}`,
    authEndpoint: `${runtimeConfig.public.sseAuthEndpoint}`,

    request: {
      headers: authHeaders,
      credentials: "include",
    },
    forceTLS: false,
    debug: runtimeConfig.public.debugMode,
  });
  return {
    provide: {
      echo: echo,
    },
  };
});
