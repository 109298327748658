import type { ModuleOptions } from "../types";

export class AccessManager {
  constructor(
    private userRoles: Ref<string[]>,
    private userPermissions: Ref<string[]>,
    private config: ModuleOptions,
  ) {}

  checkAccess(
    permissions?: string | string[],
    roles?: string | string[],
  ): boolean {
    // First check for full access roles
    if (this.hasFullAccessRole()) {
      return true;
    }

    if (!permissions && !roles) {
      return true;
    }

    if (permissions && !roles) {
      return this.hasPermission(permissions);
    }

    if (!permissions && roles) {
      return this.hasRole(roles);
    }

    return this.hasPermission(permissions!) || this.hasRole(roles!);
  }

  private hasNotPermission(binding: string | string[] | undefined) {
    if (!binding) return true;
    const activePermissions = typeof binding === "string" ? [binding] : binding;
    const isntAllowed = !activePermissions.some((permission) =>
      this.userPermissions.value?.includes(permission),
    );
    return isntAllowed;
  }

  private hasFullAccessRole(): boolean {
    // Extract full access roles from config, handling both string and array formats
    const fullAccessRoles =
      typeof this.config.fullAccessRoles === "string"
        ? [this.config.fullAccessRoles]
        : this.config.fullAccessRoles;

    // If no full access roles are configured, return false
    if (!fullAccessRoles) {
      return false;
    }

    // Check if the user has any of the full access roles
    return fullAccessRoles.some((role) => this.userRoles.value.includes(role));
  }

  private hasNotRole(binding: string | string[] | undefined) {
    if (!binding) return true;
    const activeRoles = typeof binding === "string" ? [binding] : binding;
    return !activeRoles.some((role) => this.userRoles.value?.includes(role));
  }

  private hasRole(roles: string | string[]): boolean {
    if (!roles) return true;
    if (this.hasFullAccessRole()) {
      return true;
    }
    return !this.hasNotRole(roles);
  }

  private hasPermission(permissions: string | string[]): boolean {
    if (!permissions) return true;
    return !this.hasNotPermission(permissions);
  }
}
